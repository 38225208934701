<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Balance Report
			el-row.text-left(:gutter="24")
				el-col(:span="24")
					h5 <b>Add Action</b>
			el-form(:form="form")
				el-row(:gutter="24")
					el-col(:xs="24" :sm="12" :md="6")
						el-form-item(:label="aaa")


		el-card.box-card
</template>

<style lang="scss" scoped>
.box-card {
	margin-bottom: 2rem;
}
</style>

<script>
export default {

}
</script>
